<template>
  <mu-container class="login">
    <h2>登录</h2>
    <mu-row>
      <mu-col span="12">
        <mu-form ref="form" :model="form" class="mu-demo-form">
          <mu-form-item label="用户名" prop="username" :rules="usernameRules">
            <mu-text-field v-model="form.username" prop="username"></mu-text-field>
          </mu-form-item>
          <mu-form-item label="密码" prop="password" :rules="passwordRules">
              <mu-text-field type="password" v-model="form.password" prop="password"></mu-text-field>
          </mu-form-item>
          <mu-form-item prop="remember">
            <mu-checkbox label="记住登录状态" v-model="form.remember"></mu-checkbox>
          </mu-form-item>
          <mu-form-item>
            <mu-button color="primary" @click="submit">提交</mu-button>
            <mu-button @click="clear">重置</mu-button>
          </mu-form-item>
        </mu-form>
      </mu-col>
    </mu-row>
  </mu-container>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      form: {
        username: '',
        password: '',
        remember: false
      },
      success: null,
      usernameRules: [
        { validate: (val) => !!val, message: '必须填写用户名' }
      ],
      passwordRules: [
        { validate: (val) => !!val, message: '必须填写密码' }
      ]
    }
  },
  methods: {
    clear () {
      this.$refs.form.clear()
      this.form = {
        username: '',
        password: '',
        remember: false
      }
    },
    submit () {
      this.$refs.form.validate().then(() => {
        const url = `${process.env.VUE_APP_API}/login`
        this.$toast.warning('正在登录中…')
        this.$axios
          .post(url, this.form)
          .then(response => {
            const result = response.data
            if (result.result === 1) {
              this.success = true
              this.$router.push({ path: '/' })
            } else {
              this.success = false
            }
          })
          .catch(error => {
            this.success = false
            console.log(error)
            this.$toast.error('登录失败')
          })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.login
  max-width 600px
  margin-top 60px
  padding 0 30px 30px 30px
</style>
